@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Montserrat';
  font-size: 1.03rem;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0,0,0,.85);
}

.animate-flyin {
  animation-name: flyin;
  animation-duration: 1000ms;
}

@keyframes flyin {
  0% {
    width: 0;
  }
  100% {
    width: 10rem;
  }
}

.animate-fadein {
  animation-name: fadein;
  animation-duration: 1000ms;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fadeout {
  animation-name: fadeout;
  animation-delay: 2500ms;
  animation-duration: 2500ms;

  animation-fill-mode: forwards;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

ol {
  margin-left: 35px;
  list-style-type: decimal;
}

ol li {
  margin: 8px 0;
}

.btn {
  padding: 0.25rem .8rem;

  cursor: pointer;
  transition: 175ms ease-in-out;
}

.btn-green {
  color: white;
  background-color: rgb(52, 211, 153);
  border: 2px solid rgb(52, 211, 153);
}

.btn-green:hover {
  background-color: transparent;
  color: rgb(52, 211, 153);
}

.btn-red {
  background-color: rgb(220, 38, 38);
  border: 2px solid rgb(220, 38, 38);
}

.btn-red:hover {
  background-color: transparent;
  color: rgb(220, 38, 38);
}

.btn-classic {
  border: 1px solid grey;
}

.btn-white {
  background-color: white;
  border: 2px solid white;
}

.btn-white:hover {
  color: white;
  background-color: transparent;
}

.btn-black {
  background-color: rgba(55, 65, 81);
  border: 2px solid rgba(55, 65, 81);
}

.btn-black:hover {
  color: black;
  background-color: transparent;
}

.btn-black-border {
  background-color: transparent;
  border: 2px solid rgb(55, 65, 81);
}

.btn-black-border:hover {
  background-color: black;
  color: white;
}

.center-transform {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.top-transform {
  position: absolute;
  top: 20%;
  left: 50%;

  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}

.ReactModal__Overlay {
  background-color: rgba(0,0,0,.75) !important;
}

.modal {
  position: absolute;
  background-color: rgba(0,0,0,.80);
  width: 100%;
  height: 100%;

  z-index: 1000;
}

.bg-squares {
  background-image: url('assets/images/squares-bg.png');
  background-position-y: 0;
}

.bg-forest {
  background-image: url('assets/images/bg-forest.png');
}

.grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}
.grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}
.grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}